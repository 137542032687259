import React, { Fragment } from 'react';
import {
  Typography,
  PopoverComponent as Popover,
  PopoverBody,
  PopoverHeader,
  ProgressSpinnerElement
} from 'components';
import { RootState, useAppDispatch, useAppSelector } from 'store';
import { ActiveSurvey } from 'models/models';
import { SummaryCardTypes, loadingStates } from 'models/enum';
import { getActiveSurveysData, updatePageNumber } from 'store/features/Home';
import './index.scss';

interface ISummaryCard {
  color: 'blue' | 'green' | 'yellow' | 'beige';
  icon: string;
  title: string;
  amount: string | number | null;
  customClassName?: string;
  cardType: number;
}

export const SummaryCard = ({
  color,
  icon,
  title,
  amount,
  customClassName = '',
  cardType
}: ISummaryCard) => {
  const dispatch = useAppDispatch();

  const activeSurveyDetails = useAppSelector((state: RootState) => {
    return state.home.activeSurveys.activeSurveyDetails.activeSurveyDetails;
  });

  const activeSurveyDetailsStatus = useAppSelector((state: RootState) => {
    return state.home.activeSurveys.activeSurveyDetailsStatus;
  });

  const handleOnScoll = (e: any) => {
    const { scrollTop, scrollHeight, clientHeight } =
      e.target as HTMLDivElement;
    const bottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;
    if (bottom) {
      dispatch(updatePageNumber());
      dispatch(getActiveSurveysData({}));
    }
  };

  const generateBodyLabel = (survey: ActiveSurvey, cardType: number) => {
    switch (cardType) {
      case SummaryCardTypes.ACTIVE_INVESTIGATIONS:
        return `${survey.surveyName}`;
      case SummaryCardTypes.NUMBER_OF_RESPONSES:
        return (
          <>
            {survey.surveyName} - <span>{survey.answersReceived}</span>
          </>
        );
      case SummaryCardTypes.WAITING_FOR_ANSWER:
        return (
          <>
            {survey.surveyName} - <span>{survey.pendingAnswers}</span>
          </>
        );
      case SummaryCardTypes.RESPONSE_RATE_AVERAGE:
        return (
          <>
            {survey.surveyName} - <span>{survey.responseRateAverage}%</span>
          </>
        );
      default:
        return '';
    }
  };

  const generateInfoHeading = (cardType: number) => {
    switch (cardType) {
      case SummaryCardTypes.ACTIVE_INVESTIGATIONS:
        return 'Aktive undersøkelser';
      case SummaryCardTypes.NUMBER_OF_RESPONSES:
        return 'Antall svar';
      case SummaryCardTypes.WAITING_FOR_ANSWER:
        return 'Avventer svar';
      case SummaryCardTypes.RESPONSE_RATE_AVERAGE:
        return 'Svarprosent';
      default:
        return '';
    }
  };

  const generateInfoText = (cardType: number) => {
    switch (cardType) {
      case SummaryCardTypes.ACTIVE_INVESTIGATIONS:
        return 'Oversikt over aktive undersøkelser';
      case SummaryCardTypes.NUMBER_OF_RESPONSES:
        return 'Oversikt over antall svar per aktive undersøkelse';
      case SummaryCardTypes.WAITING_FOR_ANSWER:
        return 'Oversikt over hvor mange svar som mangler for aktive undersøkelser';
      case SummaryCardTypes.RESPONSE_RATE_AVERAGE:
        return 'Den gjennomsnittlige svarprosenten (antall svar delt på antall spurte) for de aktive undersøkelsene dine.';
      default:
        return '';
    }
  };

  return (
    <div className="summery-more-info-popover">
      <Popover
        buttonContent={
          <div
            className={`summary-card-container--card-${color} ${customClassName}`}
          >
            <div className="card-summary">
              <Typography
                variant="h5"
                color="gray-900"
                style={{ margin: '0 0 8px 0', padding: 0 }}
              >
                {title}
              </Typography>

              <div className="card-summery-contents">
                <Typography
                  variant="h2"
                  color="gray-900"
                  style={{ margin: 0, padding: 0 }}
                >
                  {amount || 0}
                </Typography>

                <i className="ri-information-fill summery-card-info-icon" />
              </div>
            </div>

            <div className="card-icon">
              <i className={icon} />
            </div>
          </div>
        }
        customClass="popover-close-button"
      >
        <div className="summery-popover-content">
          <PopoverHeader>
            <Typography
              variant="h5"
              color="gray-900"
              style={{ margin: '0 0 4px 0', padding: 0 }}
            >
              {generateInfoHeading(cardType)}
            </Typography>
            <Typography variant="smallRegular" color="gray-500">
              {generateInfoText(cardType)}
            </Typography>
          </PopoverHeader>

          <PopoverBody onScroll={handleOnScoll}>
            {activeSurveyDetails?.map(
              (survey: ActiveSurvey, index: React.Key) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className="summery-popover-item" key={index}>
                  <i className="ri-checkbox-blank-circle-fill" />
                  <Typography
                    variant="baseMedium"
                    color="brand-midnight"
                    customClassName="summery-item-details"
                  >
                    {generateBodyLabel(survey, cardType)}
                  </Typography>
                </div>
              )
            )}
          </PopoverBody>

          {activeSurveyDetailsStatus === loadingStates.LOADING && (
            <div className="summary-content-spinner">
              <ProgressSpinnerElement size="default" color="gray" />
            </div>
          )}
        </div>
      </Popover>
    </div>
  );
};
